

<mat-dialog-content>

    <span style="text-align: center;" class="font_size"><h4 ><b>{{headerLabel}}</b></h4></span>
        <mat-spinner [diameter]="30" *ngIf="showSpinner" strokeWidth="15"></mat-spinner>
        <table align="center" style="margin-top: 5px;height: 80px;" *ngIf="verifyType=='User'">
            <tr > 
                <td class="boldFont">  {{inputLable}} </td>
                <td> <input  maxlength="9" (keypress)="numberOnly($event)" [(ngModel)]="userNameInput"> </td>
                <td>  <button class="submit verifyButton"  mat-raised-button color="primary" (click)="verifyUser()" tabindex="1">{{verifyButtonLabel}}</button> </td>
            </tr>

            <tr *ngIf="isUserVerified && verifiedUserDetails['Status']=='Valid'">
                <td class="boldFont"> User Name:</td>
                <td> <input disabled [(ngModel)]="verifiedUserName"> </td>
                <td></td>
            </tr>
            
            <tr *ngIf="isUserVerified && verifiedUserDetails['Status']=='Invalid'">
                <td colspan="3" style="color:red;text-align:center">Invalid User SSO.</td>
            </tr>
              
         </table> 
        <table align="center"  *ngIf="verifyType=='DL'" style="margin-top: 5px;height: 100px;width:100%;" class="dltable">
            <tr>
                <td class="dltabletd1 boldFont">  {{inputLable}} </td>
                <td class="dltabletd2"> <input  (keypress)="numberOnly($event)" [(ngModel)]="dlNameInput" class="dlSearchInput"> </td>
                <td class="dltabletd3">  <button class="submit verifyButton"  mat-raised-button color="primary" (click)="verifyDL()" tabindex="1">{{verifyButtonLabel}}</button> </td>
            </tr> 
            <tr *ngIf="isDlVerified && verifiedDlUsersDetails['Status']=='Valid'">
                <td class="boldFont"> DL Users:</td>
                <td>
                <div class="DlUserListDiv">
                    <div *ngFor="let user of verifiedDlUsersDetails['DLUsers']">
                        <span >{{user.First}} {{user.Last}}</span>
                </div></div>
                </td>
                <td></td>
            </tr>
            <tr *ngIf="isDlVerified && verifiedDlUsersDetails['Status']=='Invalid'">
                <td colspan="3" style="color:red;text-align:center">
                    {{verifiedDlUsersDetails.ErrorMessage}}
                </td>
            </tr>
              
        </table>
        
   
    
</mat-dialog-content>


<mat-dialog-actions align="center" style="margin-top: 25px !important;margin-bottom:5px" *ngIf="verifyType=='User'">

	<button class="submit"  mat-raised-button color="primary" (click)="onConfirmClickUser()" tabindex="1" *ngIf="isUserVerified && verifiedUserDetails['Status']=='Valid'">{{confirmButtonText}}</button>
	<button class="cancel"  mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>

<mat-dialog-actions align="center" style="margin-top: 25px !important;margin-bottom:5px" *ngIf="verifyType=='DL'">

	<button class="submit"  mat-raised-button color="primary" (click)="onConfirmClickDL()" tabindex="1" *ngIf="isDlVerified && verifiedDlUsersDetails['Status']=='Valid'">{{confirmButtonText}}</button>
	<button class="cancel"  mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
</mat-dialog-actions>
