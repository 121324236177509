<div class="container calcHeight100vhMinus200">
  <form [formGroup]="formGroup" class="inputForm">

  
   
  
    <ng-container *ngFor="let input of inputList; index as i">

      <!-- Plant / Line Dropdown of Appsync -->
      <ng-container *ngIf="!input['API Binding Attribute'] && input['Name']=='Plant'">
        <mat-form-field class="slicer margin_left dynamicFeild">
          <mat-label> Plant</mat-label>
            <mat-select  [formControlName]="input['Name']" (selectionChange)="onPlantSelect();clearSelection(i)"
              [compareWith]="compareByPlantLine" required>
              <mat-option value="null" disabled selected>Select...</mat-option>
              <mat-option *ngFor="let s of siteData" [value]="s">
                {{s.name}}
              </mat-option>
            </mat-select>
        </mat-form-field> 
      </ng-container>

      <mat-form-field class="slicer margin_left dynamicFeild" *ngIf="input['Name']=='system' && showSystem" (click)="systemSelected()">
        <mat-label>System</mat-label>
        <mat-select  (selectionChange)="onSystemSelect();checkForDataBindingAPIS(i);clearSelection(i)" [formControlName]="input['Name']" required [compareWith]="compareSystem">
          <mat-option disabled selected>Select...</mat-option>
          <mat-option *ngIf="filterSystemData.length" [value]="all">All</mat-option>
          <mat-option *ngFor="let sys of filterSystemData" [value]="sys.system_id">
            {{sys.system_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="!input['API Binding Attribute'] && input['Name']=='Line' && dynDropDownData['lineList']">
        <mat-form-field class="slicer margin_left dynamicFeild" (click)="lineSelected();">
          <mat-label> Line</mat-label>
            <mat-select  [formControlName]="input['Name']" (selectionChange)="clearSelection(i)"
              [compareWith]="compareByPlantLine" required>
              <mat-option disabled selected>Select...</mat-option>
              <mat-option [value]="all">All</mat-option>
              <mat-option *ngFor="let s of lineData" [value]="s">
                {{s.name}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </ng-container>
      

      <!--Other dynamic Dropdowns-->
      <ng-container *ngIf="input['API Binding Attribute']">
        <mat-form-field class="slicer margin_left dynamicFeild" (click)="filterDynamicDropDownList(i);">
          <mat-label>{{input.Name}}</mat-label>
            <mat-select  [formControlName]="input['Name']" (selectionChange)="clearSelection(i)"
              [compareWith]="compareDynamic" required>
              <mat-option disabled selected>Select...</mat-option>
              <mat-option [value]="all">All</mat-option>
              <mat-option *ngFor="let s of input['List']" [value]="s">
                {{s.Description}}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </ng-container>

    </ng-container>


  </form>

  
  <table  class="width_100 AlarmTable" *ngIf="alarmTableShow">
    <thead class="">

      <tr>
        <th class="widthh_75">Alarm Name</th>
        <th class="hideForMobile" width="20%">SubType</th>
        <th class="coustomHeader" width="20%">
          <table class="subscriptionHeaderTable">
            <tr>
              <td colspan="3" class="subscriptionHeading">Subscription</td>
            </tr>
            <tr>
              <td style="width:5%" >Email</td>
              <td style="width:5%" >SMS</td>
              <td style="width:10%" class="hideForMobile">UserGroup</td>
              <td style="width:10%" class="hideForDesktop">UserGrp</td>
            </tr>
          </table>
        </th>
        <th class="widthh_5 hideForMobile">Subscription Count</th>
        <th class="widthh_5 hideForDesktop">Count</th>
      </tr>
    </thead>

    

    <tbody>
      <ng-container *ngFor="let mainData of dataGroupBySystem;index as i ">
       <tr >
        <td colspan="4" class="systemHeadRow">  {{mainData['system_name']}} </td>       
       </tr>
      <ng-container *ngFor="let data of mainData.alamData;index as j">
        <tr >
         <td  (click)="navigateToalarm(data,mainData['system_id'])" style="text-align: left;padding-left: 5px;" width="40%" class="alarmNameTD">{{data.alarmName}}</td>
         <td  class="hideForMobile" style="text-align: left;padding-left: 5px;" width="30%">{{data.sub_type}}</td>
        <td width="20%">
          <table style="width:100%">
            <tr>
              <td class="alarm_email_checkbox_column"><mat-checkbox class="" (change)="onSubscriptionTypeSelect($event,'email', data)" [checked]="data.email == 1"> </mat-checkbox><span class="material-icons repeatIcon" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">refresh</span></td>
              <td class="alarm_sms_checkbox_column"><mat-checkbox class="" (change)="onSubscriptionTypeSelect($event,'sms', data)" [checked]="data.Sms ==1"></mat-checkbox><span class="material-icons repeatIcon" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">refresh</span></td>
              <td class="alarm_usrgrp_checkbox_column">
                <mat-checkbox class="UserGroupCheckBox" (change)="onUserGroupSelect($event, data)" [checked]="data.group == 1" disabled></mat-checkbox>
                <button class="setUserGroupButton hideForMobile" (click)="showUserGroupSelectionPopup(data)">Set Group</button>
                <button class="setUserGroupButton hideForDesktop" (click)="showUserGroupSelectionPopup(data)">Set</button>
              </td>
            </tr>
          </table>
        </td>
         <td > <span class="alarmCount">{{data.subscriptionData.length}}</span>
          <mat-icon style="margin-top: 5px;" style="vertical-align: middle;" *ngIf="!data.showSubscribersTable" (click)="showSubscribersTable(i,j)">expand_more</mat-icon>
          <mat-icon style="margin-top: 5px;" style="vertical-align: middle;" *ngIf="data.showSubscribersTable" (click)="showSubscribersTable(i,j)">expand_less</mat-icon> 
              
        </td>
          </tr> 
      
      
      <ng-container *ngFor ="let x of data['subscriptionData']">
        <tr  style="background-color: transparent !important;" *ngIf="data.showSubscribersTable">
          <td colspan="4">
            <table class="subscribe_list_alarmTable">
              <tbody>
               <tr *ngIf="x['Type']=='user'">
                 <td style="border-right: none !important; padding-left: 5px !important" class="leftAlignTD">{{x.FirstName}} {{x.LastName}}</td>
                 <td style="border-right: none !important;" colspan="2">
                  <mat-checkbox disabled class="padding_right" [checked]="x.email"><span class="label_heading">eMail</span>
                    
                </mat-checkbox>
                <span class="immediate_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Immediate: <mat-checkbox disabled class="" [checked]="x.EmailImmedidateAlarm"></mat-checkbox></span>  
                <span class="repeat_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Repeat(m):{{x.EmailRepeatInterval}}</span>
                <span class="seperationLine" *ngIf="data['criterion']['RepeatEnable']=='1'"> | </span>
                <mat-checkbox  disabled class="padding_right" [checked]="x.Sms"><span class="label_heading">SMS</span>
                 </mat-checkbox> 
                  <span  class="immediate_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Immediate: <mat-checkbox disabled class="" [checked]="x.SMSImmedidateAlarm"></mat-checkbox></span>  
                  <span class="repeat_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Repeat (m): {{x.SMSRepeatInterval}}</span> 
                

                 </td>
               
               </tr>
               <tr *ngIf="x['Type']=='group'">
                <td style="border-right: none !important; padding-left: 5px !important" class="leftAlignTD">{{x.GroupName}}</td>
                <td style="border-right: none !important;" colspan="2">
                  <span  class="immediate_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Immediate: <mat-checkbox disabled class="" [checked]="x.EmailImmedidateAlarm"></mat-checkbox></span>  
                  <span class="repeat_div" [ngClass]="data['criterion']['RepeatEnable']!='1' ? 'displayHidden' : 'null'">Repeat (m): {{x.EmailRepeatInterval}}</span>
                </td>
              
              </tr>
              </tbody>
            </table>
          </td>

        </tr>

      </ng-container>

    </ng-container> 
  </ng-container>
  <tr *ngIf= "checkAlarmSummaryData">
    <td colspan="4" style="text-align:center !important"> No Data Found </td> 
  </tr>
    </tbody>
  </table>
  
</div>